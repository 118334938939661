<script setup>
import { ref, onMounted, computed, inject } from "vue"
import { useSettingsStore } from '../stores/settings'
import { useUsersStore } from "../stores/users";

import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

const userStore = useUsersStore()

const settingsID = 'settings-calendar-settings'

const store = useSettingsStore()

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    email: yup.string().email().required(),
  }),
});

const { errors:registrationErrors, defineField: defineRegistrationField, handleSubmit: handleRegistrationSubmit } = useForm({
  validationSchema: yup.object({
    password: yup.string().min(6).max(24).required(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required(),
    oldPassword: yup.string().min(6).max(24).required()
  }),
});

const logout = async () => {
    await userStore.logout(pb)
    // forward to login
    router.push('/login')
}


const onPasswordChange = handleRegistrationSubmit( async (values, {resetForm, setFieldError}) => {
    try {
     await userStore.updatePassword(values.password, values.confirmPassword, values.oldPassword, pb)
     resetForm()
    } catch (error) {
      setFieldError('password', error.message)
    }
});

const onEmailChange = handleSubmit( async (values, {resetForm, setFieldError}) => {
    try {
     await userStore.updateEmail(values.email, pb)
    } catch (error) {
      setFieldError('email', error.message)
    }
});

const canSaveEmailChanges = computed(() => {
    if (newEmail.value === pb?.authStore?.model?.email) {
        return false
    }
    return true
})

const canSavePasswordChanges = computed(() => {
    if (newPassword.value === '') {
        return false
    }
    return true
})

let pb

onMounted( async () => {
  pb = inject('client')
  
  newEmail.value = pb?.authStore?.model?.email ?? ''
})

const [newEmail, newEmailAttrs] = defineField('email')
const [newPassword, newPasswordAttrs] = defineRegistrationField('password');
const [passwordConfirmation, passwordConfirmationAttrs] = defineRegistrationField('confirmPassword');
const [oldPassword, oldPasswordAttrs] = defineRegistrationField('oldPassword');

</script>

<template>
    <h3 class="separator-left">Account Settings</h3>
    <form @submit="onEmailChange">
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-8">
      <label for="accountEmail" class="middle">Email</label>
    </div>
    <div class="cell medium-4 small-4 large-4">
      <input id="accountEmail"  v-model="newEmail" v-bind="newEmailAttrs"  type="email">
      <span class="form-error" :class="{'is-visible': errors.email}">{{ errors.email }}</span>
    </div>
    <div class="cell medium-3">
      <h4 class="subheader"><input class="button" :disabled="!canSaveEmailChanges" type="submit" value="Save Changes"></h4>
    </div>
  </div>
</form>
<form @submit="onPasswordChange">
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-8">
      <label for="accountPassword" class="middle">Password</label>
    </div>
    <div class="cell medium-4 small-4 large-4">
      <input id="accountPassword" v-model="newPassword" v-bind="newPasswordAttrs" type="password" />
      <span class="form-error" :class="{'is-visible': registrationErrors.password}">{{ registrationErrors.password }}</span>
    </div>
    <div class="cell medium-3">
      <h4 class="subheader"></h4>
    </div>
  </div>
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-8">
      <label for="accountPasswordConfirm" class="middle">Confirm Password</label>
    </div>
    <div class="cell medium-4 small-4 large-4">
      <input id="accountPasswordConfirm" v-model="passwordConfirmation" v-bind="passwordConfirmationAttrs" type="password" />
      <span class="form-error" :class="{'is-visible': registrationErrors.confirmPassword}">{{ registrationErrors.confirmPassword }}</span>
    </div>
    <div class="cell medium-3">
    </div>
  </div>
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-8">
      <label for="accountOldPassword" class="middle">Old Password</label>
    </div>
    <div class="cell medium-4 small-4 large-4">
      <input id="accountOldPassword" v-model="oldPassword" v-bind="oldPasswordAttrs" type="password" />
      <span class="form-error" :class="{'is-visible': registrationErrors.oldPassword}">{{ registrationErrors.oldPassword }}</span>
    </div>
    <div class="cell medium-3">
      <h4 class="subheader"><input class="button" :disabled="!canSavePasswordChanges" type="submit" value="Save Changes"></h4>
    </div>
  </div>
</form>
<div>
    <button @click="logout()" class="button secondary" type="button">Logout</button>
  <RouterLink class="button clear secondary" to="/password-reset">Reset Password</RouterLink>
</div>
  

</template>
