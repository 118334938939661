import { ref, onMounted, onUnmounted, computed, watch } from 'vue'
import { useSettingsStore } from '../stores/settings'

import ical from "ical.js";

function parseCalendar(calendarData) {
  const jcalData = ical.parse(calendarData)
  const timezoneBackup = 'America/New_York'
    let vcalendar = new ical.Component(jcalData)
    vcalendar = ical.helpers.updateTimezones(vcalendar)
    
    
    var timezoneComp = vcalendar.getFirstSubcomponent('vtimezone');
    // console.log('getTimeZoneByID', vcalendar.getTimeZoneByID('America/New_York'))
    

    var tzid
    if (!timezoneComp) {
      // console.log('getTimeZoneByID', vevents[0].getFirstPropertyValue('dtstart'))
      timezoneComp = (new ical.Component('test', vcalendar))
      timezoneComp.addPropertyWithValue('tzid', timezoneBackup) 
    }
    tzid = timezoneComp.getFirstPropertyValue('tzid');
    
    var timezone = new ical.Timezone({
      component: timezoneComp,
      tzid
    });

    const vevents = vcalendar.getAllSubcomponents('vevent')
    const parsedEvents = vevents.flatMap(vevent => {
      const here = vevent.getFirstPropertyValue('tzid')
      // console.log('here', here)
      // if (here === null) {
      //   vevent.updatePropertyWithValue('tzid', timezoneBackup)
      // }
      const event = new ical.Event(vevent)
      
      

      
      // console.log('event', event.TZID)
      
      let startDate = event.startDate || vevent.getFirstPropertyValue('dtstart')
      let endDate = event.endDate || vevent.getFirstPropertyValue('dtend')
      const summary = event.summary || vevent.getFirstPropertyValue('summary')
      const description = event.description || vevent.getFirstPropertyValue('description')
      const location = event.location || vevent.getFirstPropertyValue('location')
      
      const recur = event.recur || vevent.getFirstPropertyValue('rrule')

      const start = startDate.zone

      
      
      
      
      
      const isAllDay =
        startDate &&
        endDate &&
        startDate.isDate &&
        endDate.isDate &&
        !startDate.hasTime &&
        !endDate.hasTime

        // console.log('start', startDate.toJSDate().toString())
      // start.updatePropertyWithValue('tzid', timezoneBackup)
      // if (startDate.zone.tzid === 'floating') {
      //   console.log('startb4', startDate.toJSDate().toString())
      //   console.log('event.summary', event.summary)
        
      //   startDate.zone.tzid = timezoneBackup
      //   endDate.zone.tzid = timezoneBackup
      //   console.log('starta', startDate.toJSDate().toString())
      // } else {
      //   console.log('event.summary', event.summary)
      // }

        if (isAllDay) {
          // if (tzid) {
            // console.log('summary', summary)
            
            // console.log('startDateb4', startDate.toJSDate().toString())
            // if (vevent.getFirstPropertyValue('tzid') !== tzid) {
            //   startDate = startDate.convertToZone(timezone)
            //   endDate = endDate.convertToZone(timezone)
            // }
            // console.log('startDatea', startDate.toJSDate().toString())
          // }

          // console.log('summary', summary)
          // console.log('startDate', startDate)
        }
        
        
        let end = endDate ? endDate.toJSDate() : null

      let returnValue = {
        start: startDate ? startDate.toJSDate() : null,
        end: end,
        title: summary || '',
        allDay: isAllDay,
        content: description,
        location
      }
      // console.log('returnValue', returnValue)

      if (event.isRecurring()) {
        // console.log('isRecurrenceException()', event.isRecurrenceException())
        
        const duplicates = []
        // end date is 2 months from current date
        const extended = 1
        const currentDate = new Date()
        const endDate = currentDate.setMonth(currentDate.getMonth() + extended)
        // let rrule = vevent.getFirstProperty('rrule');
        // let dtstart = vevent.getFirstPropertyValue('dtstart')
        // let iter = rrule.iterator(dtstart)
        // let limit = 10
        // let start = 0
        // let next;

        // for (let next = iter.next(); next && !iter.completed && start < limit; next = iter.next()) {
        //   // do something with next
        //   console.log('next', next)
        //   start++
        // }
        
        let expand = new ical.RecurExpansion({
          component: vevent,
          dtstart: vevent.getFirstPropertyValue('dtstart')
        });
        
        // // next is always an ICAL.Time or null
        // let next;
        // for (let index = 0; index < 1; index++) {
        //   try {
        //     next = expand.next()
        //   console.log('next', next)
        //   } catch (error) {
            
        //   }
          
        // }

        // next is always an ICAL.Time or null
        let next;
        
        while ((next = expand.next()) && next.toJSDate() < endDate) {
          // do something with next
          // console.log('next', next)
          // figure out the difference between the dates

          // Fix the timezone when the recurring events are all day events
          if (isAllDay) {
            // if (tzid) {
              // if (vevent.getFirstPropertyValue('tzid') !== tzid) {
              //   next = next.convertToZone(timezone)
              // }
            // }
          }
          const difference = next.toJSDate() - startDate.toJSDate()
          
          // edit the return value
          const newEvent = structuredClone(returnValue)
          newEvent.start = next.toJSDate()
          
          newEvent.end = new Date(end.getTime() + difference)
          // console.log('newEvent', newEvent)
          
          // push the return value onto the list
          duplicates.push(newEvent)
        }
        return duplicates
      }
      return returnValue
    })
    .sort((a, b) => {
      const dateA = new Date(a.start)
      const dateB = new Date(b.start)
      if (dateA < dateB) {
        return -1
      } else  if (dateA > dateB) {
        return 1
      } else {
        return 0
      }
    })
  return parsedEvents
}


export default function useCalendarEvents(workerUrl, calendarUrls) {
  const store = useSettingsStore()
  const events = ref([])
  // const eventsAsDates = ref([])

  const allGoodEvents = ref([])

  const combinedEvents = computed(() => {
    return allGoodEvents.value.flat().sort((a, b) => a.start < b.start ? -1 : 1)
  })

  const calendarEtags = []

  const fetchCalendarEvents = async (calendar) => {
    if (calendar.url === "" || !calendar.enabled) {
      return
    }
    try {
      const options = {
        headers: []
      }
      // look for the etag
      const requestEtagIndex = calendarEtags.findIndex(each => each.url === calendar.url)
      if (requestEtagIndex !== -1) {
        options.headers['If-None-Match'] = calendarEtags[requestEtagIndex].etag
      }/* else {
        options.cache = "no-cache"
      }
      console.log('requestEtagIndex', requestEtagIndex)
      
      console.log('calendarEtagsB4', calendarEtags)
      console.log('options', options)*/
      
      const workerUrlWithParams = `${workerUrl}?url=${encodeURIComponent(calendar.url)}`
    const response = await fetch(workerUrlWithParams, options)
    const responseEtag = response.headers.get('etag')
    // console.log('response.responseEtag', responseEtag)
    
    
    if (requestEtagIndex === -1) {
      calendarEtags.push({
        url: calendar.url,
        etag: responseEtag
      })
    } else {
      calendarEtags[requestEtagIndex].etag = responseEtag
    }
    // console.log('calendarEtagsA', calendarEtags)
    if (response.status === 304) {
      return
    } else if (response.status === 503) {
      const retry = response.headers.get('Retry-After') ?? 60
      delay = parseInt(retry, 10) * 1000
      return
    }

    if (response.headers.get('Content-Length') == 0) {
      return
    }
    const calendarData = await response.text()

    const calendarEvents = parseCalendar(calendarData)
    // console.log('calendarEvents', calendarEvents)
    

    const events = calendarEvents.map(each => {
      
            // each.start = new Date(each.start)
            // each.end = new Date(each.end)

        each.class = calendar.color
        
        
        return each
    })

    // const eventsAsDates = calendarEvents
    return {
      events,
      // eventsAsDates
    }

    
    } catch (error) {
      console.error(error);
      throw error
    }
  }

  const fetchAllCalendarEvents = async () => {
    if (!calendarUrls.value.length) {
      allGoodEvents.value = []
      return
    }
    const requests = calendarUrls.value.map(calendar => fetchCalendarEvents(calendar))

    // const result = await Promise.allSettled(requests)

    // This will cause a problem in the future with multiple calendars
    try {
      const result = await Promise.allSettled(requests)

      // Go through each calendar request
      // if it is good, then add it to the allGoodEvents array or update it with the calendar url as the index
      // if it is bad, then log the error and do nothing
      result.forEach((each, index) => {
        if (each.status === 'fulfilled') {
          // console.log('each', each)
          if (each.value?.events) {
            allGoodEvents.value[index] = each.value.events
          } else {
            // remove events from calendars that are disabled
            allGoodEvents.value[index] = []
          }
        } else {
          if (!each.reason.message.includes("good")) {
            console.log('Unable to load.', each.reason)
          }
        }
      })

      if (result.every(each => each.status === 'fulfilled')) {
        delay = 0
      }

      // events.value = result.flatMap(each => each?.events ?? [])
      // eventsAsDates.value = result.flatMap(each => each?.eventsAsDates ?? [])
    } catch (error) {
      if (!error.message.includes("good")) {
        console.log('Unable to load.', error)
      }
      
      
    }
  }

  let interval
  let delay = 0

  const scheduleFetch = () => {
    interval = setTimeout(() => {
      try {
        fetchAllCalendarEvents().finally(scheduleFetch);
      } catch (error) {
        console.log('unable to load')
        console.error();
      }
    }, store.calendarUpdateFrequency + delay);
  };

watch(() => store.isOffline, (newEvents, oldEvents) => {
  if (!store.isOffline) {
    fetchAllCalendarEvents()
    scheduleFetch()
  } else {
    // Don't try to update when we know things are offline
    clearTimeout(interval)
  }
  
}, { deep: true });

watch(() => calendarUrls, (newEvents, oldEvents) => {
  if (!store.isOffline) {
    fetchAllCalendarEvents()
    scheduleFetch()
  }
  // } else {
  //   // Don't try to update when we know things are offline
  //   clearTimeout(interval)
  // }
  
}, { deep: true });

onMounted(() => {
  // if (!store.isOffline) {
  //   fetchAllCalendarEvents()
  //   scheduleFetch();
  // // return () => clearTimeout(interval);
  // }
  
});

onUnmounted(() => {
  clearTimeout(interval)
  interval = null; // Clear the interval when the component is unmounted
})



  return { events: combinedEvents }
}