const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    // timeZone: "America/New_York",
  });

  const formattedTime = new Intl.DateTimeFormat('en-US', {

    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    // timeZone: "America/New_York",
  });
  
  
  function dateToISODate(date) {
    const parts = {}
    formattedDate.formatToParts(date).forEach( each => {
      parts[each.type] = each.value
    })
  
    return `${parts.year}-${parts.month}-${parts.day}`
  }

  function formatTime(time) {
    const date = new Date(time);
    // const hours = date.getHours() % 12 || 12;
    // const minutes = date.getMinutes().toString().padStart(2, "0");
    // const ampm = date.getHours() >= 12 ? "pm" : "am";
    // return `${hours}:${minutes}${ampm}`;
    return formattedTime.format(date)
  }

  function getDaysDifference(date1, date2) {
    // Convert both dates to milliseconds
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const diffDays = Math.round(Math.abs((date1 - date2) / oneDay));
  
    return diffDays;
  }

  function getMinutesDifference(date1, date2) {
    // Convert both dates to milliseconds
    const oneMinute = 60 * 1000; // hours * minutes * seconds * milliseconds
    const diffMinutes = Math.round(Math.abs((date1 - date2) / oneMinute));
  
    return diffMinutes;
  }

  /**
   * Adds a specified number of days to a given date.
   * 
   * @param {Date} date - The date to which days will be added.
   * @param {number} days - The number of days to add.
   * @returns {Date} A new Date object with the specified number of days added.
   */
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function getDayOfMonth(dateString) {
    if (!/\dT/i.test(dateString)) {
      dateString += "T00:00:00";
    }
    const date = new Date(dateString);
    const day = date.getDate()
    return day;
  }

  /**
   * Gets the day name for datestring in YYYY-MM-DD format.
   * 
   * @param {string} dateString - The date string to be converted.
   * @returns {string} The day name.
   */
  function getDayName(dateString) {
    if (!/\dT/i.test(dateString)) {
        dateString += "T00:00:00";
      }
      const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  }

  /**
   * Determines the relative day name for a given date string.
   * 
   * @param {string} dateString - The date string to be evaluated.
   * @param {Date} [currentDate = new Date()] - The current date to compare with.
   * @returns {string} The relative day name (Today, Tomorrow, or Upcoming).
   */
  function getRelativeDayName(dateString, currentDate = new Date()) {
    if (!/\dT/i.test(dateString)) {
        dateString += "T00:00:00";
      }
    const storedDate = new Date(dateString)

    const currentDayISO = dateToISODate(currentDate)
    const storedDateISO = dateToISODate(storedDate)
    
    const nextDayISO = dateToISODate(addDays(currentDate, 1));
    
    const isSameDay = currentDayISO === storedDateISO
    const isNextDay = nextDayISO === storedDateISO;
    if (isSameDay) {
      return "Today"
    } else if (isNextDay) {
      return "Tomorrow"
    } else {
      return "Upcoming"
    }
  }

  /**
   * Determines if a given date string is today or tomorrow.
   * 
   * @param {string} dateString - The date string to be evaluated.
   * @param {Date} [currentDate = new Date()] - The current date to compare with.
   * @returns {boolean} True if the date is today or tomorrow, false otherwise.
   */
  const isTodayOrTomorrow = (dateString, currentDate = new Date()) => {
    if (!/\dT/i.test(dateString)) {
        dateString += "T00:00:00";
      }
    const storedDate = new Date(dateString)

    const currentDayISO = dateToISODate(currentDate)
    const storedDateISO = dateToISODate(storedDate)
    
    const nextDayISO = dateToISODate(addDays(currentDate, 1));
    
    const isSameDay = currentDayISO === storedDateISO
    const isNextDay = nextDayISO === storedDateISO;
    return isSameDay || isNextDay
  }

  export {
    dateToISODate,
    formatTime,
    getDaysDifference,
    getMinutesDifference,
    getDayOfMonth,
    addDays,
    getDayName,
    getRelativeDayName,
    isTodayOrTomorrow
  }