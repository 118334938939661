<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useSettingsStore } from '../stores/settings'
const store = useSettingsStore()
import Agenda from "./Agenda.vue"

import { getDayName, getDayOfMonth, getRelativeDayName, isTodayOrTomorrow } from "../utils/dateUtils";

import { groupUpcomingEvents } from '../utils/eventUtils'

const props = defineProps({
  events: {
    type: Object,
    required: true
  }
});

// const formattedTime = new Intl.DateTimeFormat('en-US', {

//   hour: 'numeric',
//   minute: '2-digit',
//   hour12: true,
//   timeZone: "America/New_York",
// });

// const formattedDate = new Intl.DateTimeFormat('en-US', {
//   year: 'numeric',
//   month: '2-digit',
//   day: '2-digit',
//   timeZone: "America/New_York",
// });



const timeFrom = "08:00";
const timeTo = "20:00";
const disableViews = ["years", "year", "month", "week", "day"];
// const format = {
//   timeslotTime: formatTime,
// };

const upcomingEvents = computed(() => {
  return props.events
    .filter((event) => new Date(event.end) >= new Date())
    .slice(0, 5);
});

const upcomingEventsLimit = 5
const upcomingEventsDaysCutoff = 14

const filteredEvents = ref([])

const upcomingEventsGrouped = computed(() => {
  return groupUpcomingEvents(filteredEvents.value, upcomingEventsLimit, upcomingEventsDaysCutoff)
});

const currentDate = ref(new Date())

watch(() => props.events, (newEvents, oldEvents) => {
  updateFilteredEvents()
}, { deep: true });

// This only runs the grouping if the events have actually changed from what we currently have.
const updateFilteredEvents = () => {
  const current = props.events.filter((event) => new Date(event.end) >= new Date())
  const comparison = JSON.stringify(current) === JSON.stringify(filteredEvents.value)
  // console.log('current', current)
  // console.log('filteredEvents.value', filteredEvents.value)
  const didTodayChange = new Date().getDate() !== currentDate.value.getDate()
  
  // console.log("Result", comparison);
  if (!comparison || didTodayChange) {
    // console.log("Updating");
    filteredEvents.value = current
    currentDate.value = new Date()
  }
}

let interval

onMounted(() => {
  interval = setInterval(() => {
    updateFilteredEvents()
  }, 60*1000)
  return () => clearInterval(interval);
});

onUnmounted(() => {
  clearInterval(interval)
})

// Before or after?
const agendaTimePosition = "after"

const showPlayer = ref(false)
const showButtons = ref(false)

const clickAudio = () => {
  const audioElements = document.querySelector('audio')
  console.log('audioElements', audioElements)
  try {
    audioElements.play()
  } catch (error) {
    console.log('error', error)
    
  }
  
  
}

const shouldShowLoadingText = computed(() => {
  return (store.calendars?.length ?? 0) && store.hasEnabledCalendars && !(props.events?.length ?? 0)
})

</script>

<template>
  <div>
    <!-- <vue-cal
      :events="events"
      :time-from="timeFrom"
      :time-to="timeTo"
      :disable-views="disableViews"
      :format="format"
    /> -->

    <div class="agenda grid-container">
      <h1  @click="showButtons = !showButtons"  class="font-bold">Upcoming Events<span v-if="store.isOffline" class="primary badge float-right">✕</span></h1>
      <audio v-show="showPlayer" controls>
  <source src="/hotel-bell-ding.mp3" type="audio/mpeg" />
  <!-- fallback for non-supporting browsers goes here -->
  <p>
    Your browser does not support HTML audio, but you can still
    <a href="/hotel-bell-ding.mp3">download the music</a>.
  </p>
</audio>
<button v-show="showButtons" type="button" class="button" @click="showPlayer= !showPlayer">Show player</button>
                <!-- <audio v-show="true" src="/hotel-bell-ding.mp3" type="audio/mpeg" autoplay="" muted="false" playsinline=""></audio> -->
              <button v-show="showButtons" id="alertSound" class="button" type="button" @click="clickAudio()">Alert</button>
      <h2 v-if="!store.hasEnabledCalendars">Add a calendar to get started. <RouterLink class="button large" to="/preferences">Add Calendar</RouterLink></h2>
      <template v-else>
      <h3 v-if="shouldShowLoadingText">Loading Events</h3>
      <Transition name="fade">
        <div v-if="!shouldShowLoadingText">
          <template v-if="!upcomingEventsGrouped.length">
            <TransitionGroup name="list" tag="div">
            <div v-for="(day, key) in upcomingEventsGrouped" :key="key">
              <h2 class="" v-if="isTodayOrTomorrow(key)">{{ getDayOfMonth(key) }}  {{ getDayName(key) }} - {{ getRelativeDayName(key) }}</h2>
              <h2 v-else>{{ getDayOfMonth(key) }} {{ getDayName(key) }}</h2>
              <template v-for="event in day" :key="event.start">
                <Agenda :event="event" :day="key" :agenda-time-position="agendaTimePosition" @alert="clickAudio"></Agenda>
              </template>
              
            </div>
          </TransitionGroup>
          </template>
          <template v-else>
            <h3>No events in the next 2 weeks</h3>
          </template>
        </div>
      </Transition>
    </template>
    </div>
  </div>
</template>


<style>
.agenda {
  margin-top: 2rem;
}

.event-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.event-time {
  margin-right: 1rem;
  font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* .list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
} */
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>