<script setup>
import { ref, onMounted, computed } from "vue"
import { useSettingsStore } from '../stores/settings'
import { useUsersStore } from "../stores/users";

const settingsID = 'settings-calendar'

const store = useSettingsStore()
const userStore = useUsersStore()

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    name: yup.string().max(30).min(1).required(),
    url: yup.string().url().required(),
  }),
});

// const loadTheme = () => store.loadTheme()

// const calendars = computed({
//   get() {
//     return store.calendars
//   },
//   set(newValue) {
//     store.setTheme(newValue)
//   }
// })

const addCalendar = async () => {
  try {
    await store.createCalendar({
    name: "New Calendar",
      url: "",
      color: "Blue",
      enabled: false,
      owner: userStore.currentUser.id
  })
  // change edit mode of last item
  const mostRecentIndex = store.calendars.length-1
  store.calendars[mostRecentIndex].editMode = true
  } catch (error) {
    // show the error to the user
    throw error
  }
  
  // store.calendars.push(
  //   {
  //     name: "Main",
  //     url: "",
  //     color: "Blue",
  //     enabled: true
  //   }
  // )
}

const saveCalendar = (calendar, index) => {
  // Fix the url if needed.
  
  if (calendar.url.substring(0, 6) === 'webcal') {
    store.calendars[index].url = calendar.url.replace('webcal', 'https')
  }

  //update the database
  store.updateCalendar(store.calendars[index].id, store.calendars[index])

  // Hide the editor
  store.calendars[index].editMode = false
}

const deleteCalendar = (calendar, index) => {
  // const foundCal = store.calendars.findIndex(each => each.url === calendar.url)
  // if (foundCal !== -1) {
  //   throw new Error("Unable to delete Calendar " + calendar.name + ". Cannot be found.")
  // }
  store.deleteCalendar(store.calendars[index].id)
  // store.calendars.splice(index, 1)
}

const getID = (theme) => `${settingsID}-${theme}`

const colorOptions = [
  {
    color: "Blue"
  },
  {
    color: "Green"
  },
  {
    color: "Magenta"
  },
  {
    color: "Purple"
  },
  {
    color: "Orange"
  },
  {
    color: "SkyBlue"
  },
]

onMounted(async () => {
  await store.initializeCalendars()
})
</script>

<template>
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-8">
      <label class="middle">Calendars</label>
    </div>
    <div class="cell medium-4 small-4 large-4">
      
        <template v-for="(each, index) in store.calendars" :key="index">
          <div @dblclick="each.editMode=true" class="callout" :class="each.color">
          <div v-if="!each.editMode">
            <h3>{{ each.name }}</h3>
            <p class="text-truncate">url: {{ each.url }}</p>
          <div class="button-group">
            <button type="button" class="button secondary" @click="each.editMode = true">Edit</button>
          </div>
          </div>
          
          <div v-else>
            <label>
            Name: 
            <input type="text" v-model="each.name">
            </label>
            <label>
            URL: 
            <input
              
              v-model="each.url"

              type="url"
              name="settings-theme"
            />
          </label>
          <label>
            Color: 
            <!-- <input type="color" :id="getID(each.name) + '-color'" v-model="each.color"> -->
            <select v-model="each.color">
              <option v-for="(option, index) in colorOptions" :key="index" :value="option.color">{{ option.color }}</option>
            </select>
            </label>
            <label>
            Enabled: 
            <div class="switch">
              <input class="switch-input" v-model="each.enabled" id="exampleSwitch" type="checkbox" name="exampleSwitch">
              <label class="switch-paddle" for="exampleSwitch">
                <span class="show-for-sr">Download Kittens</span>
              </label>
            </div>
          </label>
          <div class="button-group">
            <button type="button" class="button secondary" @click="saveCalendar(each, index)">Done</button><button type="button" class="button alert" @click="deleteCalendar(each, index)">Delete</button>
          </div>
          </div>
        </div>
        </template>
        
      
      <button class="button" type="button" @click="addCalendar">Add Calendar</button>
    </div>
    <div class="cell medium-7"></div>
  </div>
</template>
