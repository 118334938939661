<script setup>
import { onMounted, inject } from 'vue'
import { useSettingsStore } from './stores/settings'
import { useUsersStore } from './stores/users';

const pb = inject('client')

const store = useSettingsStore()
store.loadTheme()

const isOnline = () => {
  store.isOffline = !navigator.onLine
  // console.log('navigator.onLine', navigator.onLine)
}

const usersStore = useUsersStore()

onMounted(() => {
  window.addEventListener('online', isOnline)
  window.addEventListener('offline', isOnline)
  isOnline()

  // usersStore.initializeUsers()
  usersStore.checkLogin(pb)
})
</script>

<template>
  <router-view></router-view>
</template>

<style>
@media (prefers-color-scheme: light) {
  :root {
    --bg-color: #fefefe;
    --text-color: #0a0a0a;
    --white-text: #0a0a0a;
    --secondary: #f8f8f8;
    --secondary-color: #0a0a0a;
    --secondary-callout: #eaeaea;
    --selected-color: #cacaca;
    --mode: light;
    --border: #e6e6e6;
    --accordion-text: #1779ba;
    --secondary-callout-default: #eaeaea;
    /* --green: #e1faea; */
    --green-border: #13c25c;
    --red: #cc4b37;
    --red2: #bc0000;
    --blue: #1779ba;
    --orange: #d16100;
    --green: #61a773;
    --magenta: #cf4b9d;
    --skyBlue: #4b8aef;
    --purple: #9649c5;
    --yellow: #c3b200;
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: #121212;
    /* --text-color: #a5a5a5; */
    --text-color: white;
    --white-text: white;
    --secondary: #181818;
    --secondary-callout: #888888;
    --secondary-color: #a5a5a5;
    --selected-color: #1e1e1e;
    --brightness: 0.7;
    --border: #1e1e1e;
    --accordion-text: #fff;
    --secondary-callout-default: #242424;
    /* --green: #e1faea; */
    --green-border: #13c25c;
    --red: #cc4b37;
    --red2: #700d0d;
    --blue: #0d486f;
    --orange: #703b0d;
    --green: #0d7026;
    --magenta: #700d4a;
    --skyBlue: #0d3370;
    --purple: #4a0d70;
    --yellow: #70670d;
  }
}

body {
  background: var(--bg-color);
  color: var(--text-color);
}
.button {
  background-color: var(--blue);
}
label {
  color: var(--text-color);
}
input[type='search'] {
  background-color: var(--selected-color);
  color: var(--text-color);
}
input[type='search']:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}
input[type='search']::placeholder {
  color: var(--text-color);
}
input[type='text'] {
  background-color: var(--selected-color);
  color: var(--text-color);
}
input[type='text']:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}
input[type='number'] {
  background-color: var(--selected-color);
  color: var(--text-color);
}
input[type='number']:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}

input[type='url'] {
  background-color: var(--selected-color);
  color: var(--text-color);
}
input[type='url']:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}
input[type='url']::placeholder {
  color: var(--text-color);
}
select {
  background-color: var(--selected-color);
  color: var(--text-color);
}
select:focus {
  background-color: var(--selected-color);
  color: var(--text-color);
}
.callout {
  background-color: var(--selected-color);
  color: var(--text-color);
}
.callout.secondary {
  background-color: var(--secondary-callout-default);
  color: var(--white-text);
}
.callout.primary {
  background-color: var(--blue);
  color: var(--white-text);
}
.callout.alert {
  background-color: var(--red);
  color: var(--white-text);
}
thead,
tbody {
  background: var(--secondary);
  color: var(--secondary-color);
  border: 1px solid var(--selected-color);
}
tbody tr:nth-child(even) {
  background-color: var(--selected-color);
}

table.hover tr:nth-of-type(even):hover {
  background-color: var(--selected-color) !important;
  filter: brightness(130%);
}

table.hover thead tr:hover {
  filter: brightness(130%);
  background-color: var(--secondary);
}
table.hover tbody tr:hover {
  filter: brightness(130%);
  background-color: var(--secondary);
}
/* table.hover thead tr:nth-of-type(even):hover {
  filter: brightness(130%);
  background-color: var(--secondary);
} */

.Orange {
  background-color: var(--orange);
  color: var(--text);
}
.Blue {
  /* background-color: rgb(59, 86, 255); */
  background-color: var(--blue);
  color: var(--text);
}
.Red {
  background-color: var(--red2);
  color: var(--text);
}
.Purple {
  background-color: var(--purple);
  color: var(--text);
}
.Black {
  background-color: #000;
  color: white;
}
.Green {
  background-color: var(--green);
  color: var(--text);
}
.Yellow {
  background-color: var(--yellow);
  color: var(--text);
}
.Magenta {
  background-color: var(--magenta);
  color: var(--text);
}
.SkyBlue {
  background-color: var(--skyBlue);
  color: var(--text);
}
.newPersonOnDrop {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}
.dragEnter {
  /* border-style: dashed;
  border-color: black;
  border-width: 2px; */
  outline: var(--white-text) 2px dashed;
}
.disable-pointer {
  pointer-events: none;
}
.dropdown-pane-fix {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: visible;
  border: 1px solid var(--selected-color);
  border-radius: 0;
  background: var(--bg-color);
  font-size: 1rem;
  margin-top: -1rem;
}
[v-cloak] {
  display: none;
}
</style>
