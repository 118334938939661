<script setup>
import { ref, onMounted, computed } from "vue"
import { useSettingsStore } from '../stores/settings'
const themes = [
      {
        name: 'System',
        variables: []
      },
      {
        name: 'Light',
        variables: [
          {
            name: 'bg-color',
            value: '#fefefe'
          },
          {
            name: 'text-color',
            value: '#0a0a0a'
          },
          {
            name: 'white-text',
            value: '#0a0a0a'
          },
          {
            name: 'secondary',
            value: '#f8f8f8'
          },
          {
            name: 'secondary-color',
            value: '#0a0a0a'
          },
          {
            name: 'secondary-callout',
            value: '#eaeaea'
          },
          {
            name: 'selected-color',
            value: '#cacaca'
          },
          {
            name: 'blue',
            value: '#1779ba'
          },
          {
            name: 'brightness',
            value: null
          },
          {
            name: 'border',
            value: '#e6e6e6'
          },
          {
            name: 'accordion-text',
            value: '#1779ba'
          },
          {
            name: 'secondary-callout-default',
            value: '#eaeaea'
          },
          // {
          //   name: 'green',
          //   value: '#e1faea'
          // },
          {
            name: 'green-border',
            value: '#13C25C'
          },
          {
            name: 'red',
            value: '#cc4b37'
          },
          {
            name: 'red2',
            value: '#bc0000'
          },
          {
            name: 'orange',
            value: '#d16100'
          },
          {
            name: 'green',
            value: '#61a773'
          },
          {
            name: 'magenta',
            value: '#cf4b9d'
          },
          {
            name: 'skyBlue',
            value: '#4b8aef'
          },
          {
            name: 'purple',
            value: '#9649c5'
          },
          {
            name: 'yellow',
            value: '#c3b200'
          }
        ]
      },
      {
        name: 'Dark',
        variables: [
          {
            name: 'bg-color',
            value: '#121212'
          },
          // {
          //   name: 'text-color',
          //   value: '#a5a5a5'
          // },
          {
                name: 'text-color',
                value: 'white'
              },
          {
            name: 'white-text',
            value: 'white'
          },
          {
            name: 'secondary',
            value: '#181818'
          },
          {
            name: 'secondary-color',
            value: '#a5a5a5'
          },
          {
            name: 'secondary-callout',
            value: '#888888'
          },
          {
            name: 'selected-color',
            value: '#1e1e1e'
          },
          {
            name: 'blue',
            value: '#0d486f'
          },
          {
            name: 'brightness',
            value: '.7'
          },
          {
            name: 'border',
            value: '#1e1e1e'
          },
          {
            name: 'accordion-text',
            value: '#fff'
          },
          {
            name: 'secondary-callout-default',
            value: '#242424'
          },
          // {
          //   name: 'green',
          //   value: '#0B7437'
          // },
          {
            name: 'green-border',
            value: '#13C25C'
          },
          {
            name: 'red',
            value: '#7a2d21'
          },
          {
            name: 'red2',
            value: '#700d0d'
          },
          {
            name: 'orange',
            value: '#703b0d'
          },
          {
            name: 'green',
            value: '#0d7026'
          },
          {
            name: 'magenta',
            value: '#700d4a'
          },
          {
            name: 'skyBlue',
            value: '#0d3370'
          },
          {
            name: 'purple',
            value: '#4a0d70'
          },
          {
            name: 'yellow',
            value: '#70670d'
          }
        ]
      }
    ]
const settingsID = 'settings-theme'

const store = useSettingsStore()

const loadTheme = () => store.loadTheme()

const theme = computed({
  get() {
    return store.theme
  },
  set(newValue) {
    store.setTheme(newValue)
  }
})

const getID = (theme) => `${settingsID}-${theme}`
</script>

<template>
  <div class="grid-x grid-padding-x text-left">
    <div class="cell medium-3 small-8">
      <label class="middle">Theme</label>
    </div>
    <div class="cell medium-2 small-4">
      <fieldset>
        <template v-for="each in themes" :key="each.name">
          <label>
            <input
              :id="getID(each.name)"
              v-model="theme"
              type="radio"
              name="settings-theme"
              :value="each.name"
              @change="loadTheme"
            />
            {{ each.name }}
          </label>
        </template>
      </fieldset>
    </div>
    <div class="cell medium-7"></div>
  </div>
</template>
