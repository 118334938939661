<script setup>
import { ref, onMounted, computed, inject, onUnmounted } from "vue"
import { useUsersStore } from "../stores/users";
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

// Import the PocketBase JS library
// import client from '../pocketbase';

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors:registrationErrors, defineField: defineRegistrationField, handleSubmit: handleRegistrationSubmit } = useForm({
  validationSchema: yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(6).max(24).required(),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required()
  }),
});
const userStore = useUsersStore()

const loginWithPCO = async function() {
  
  

  userStore.loginWithPCO(pb)
}

const onRegistration = handleRegistrationSubmit( async (values, {resetForm, setFieldError}) => {
  // if (userStore.users.map(user => user.name.toLowerCase()).includes(values.username.toLowerCase())) {
  //   setFieldError('username', 'Username taken. Please pick another.')
  // } else {
    try {
     await userStore.registerUser(values.email, values.password, values.confirmPassword, pb)  
     resetForm()
     // go home
     if (userStore.isLoggedIn) {
        // forward to home
        router.push('/')
      }
    } catch (error) {
      setFieldError('email', error.message)
      
      if (error.data.data?.email) {
        setFieldError('email', error.data.data?.email.message)
      }
    }
    
  
  
});

let pb

onMounted( async () => {
  pb = inject('client')

  if (userStore.isLoggedIn) {
    // forward to home
    router.push('/')
  }
})

const [newEmail, newEmailAttrs] = defineRegistrationField('email')
const [newPassword, newPasswordAttrs] = defineRegistrationField('password');
const [passwordConfirmation, passwordConfirmationAttrs] = defineRegistrationField('confirmPassword');
// const [newPassword, newPasswordAttrs] = defineRegistrationField('password');
</script>

<template>
  <div class="grid-x grid-padding-x align-center">
    <div class="cell medium-3 small-8 text-center">
      <h4>Create an account</h4>
        <form @submit="onRegistration">
      
      <label>Email
      <input v-model="newEmail" v-bind="newEmailAttrs" type="email" required autocomplete="username"/>
      </label>
      <span class="form-error" :class="{'is-visible': registrationErrors.email}">{{ registrationErrors.email }}</span>
      <label>Password
      <input v-model="newPassword" v-bind="newPasswordAttrs" type="password" required autocomplete="new-password" />
      </label>
      <span class="form-error" :class="{'is-visible': registrationErrors.password}">{{ registrationErrors.password }}</span>
      <label>Password Confirmation
      <input v-model="passwordConfirmation" v-bind="passwordConfirmationAttrs" type="password" required autocomplete="new-password" />
      </label>
      <span class="form-error" :class="{'is-visible': registrationErrors.confirmPassword}">{{ registrationErrors.confirmPassword }}</span>
      <input class="button expanded" type="submit" value="Create Account">
      <!-- <button @click="showAddScreen = false" type="button" class="button secondary float-right">Cancel</button> -->
    </form>
    <RouterLink class="button hollow secondary expanded" to="/login">Login</RouterLink>
    <br>
    <small>By clicking create account, you agree to our <a href="">Terms of Service</a> and <a href="">Privacy Policy</a>.</small>
    </div>

  </div>
</template>
