import PocketBase from 'pocketbase'

export function pocketbasePlugin(pb) {
  return ({ options, store }) => {
    if (!options.pocketbase) return;
    
    const collection = options.pocketbase.collection;
    const stateKey = options.pocketbase.stateKey;

    // Subscribe to PocketBase changes
    const unsubscribe = pb.collection(collection).subscribe('*', (e) => {
      if (e.action === 'create') {
        console.log('Creation from subscription')
        store.$patch((state) => {
          state[stateKey].push(e.record);
        });
      } else if (e.action === 'update') {
        store.$patch((state) => {
          const index = state[stateKey].findIndex(item => item.id === e.record.id);
          if (index !== -1) {
            state[stateKey][index] = e.record;
          }
        });
      } else if (e.action === 'delete') {
        store.$patch((state) => {
          state[stateKey] = state[stateKey].filter(item => item.id !== e.record.id);
        });
      }
    });

    // Add a method to fetch all items from PocketBase
    store[`fetchAll${stateKey.charAt(0).toUpperCase() + stateKey.slice(1)}`] = async (options) => {
      try {
        const records = await pb.collection(collection).getFullList(options);
        store.$patch({ [stateKey]: records });
      } catch (error) {
        console.error(`Failed to fetch ${stateKey}:`, error);
      }
    };

    // Add a method to create new item in PocketBase
    store[`create${stateKey.charAt(0).toUpperCase() + stateKey.slice(1).slice(0, -1)}`] = async (data) => {
      try {
        const record = await pb.collection(collection).create(data);
        // The subscription will add it anyway
        // store.$patch((state) => {
        //   state[stateKey].push(record);
        // });
        return record;
      } catch (error) {
        console.error(`Failed to create ${stateKey.slice(0, -1)}:`, error);
      }
    };

    // Add a method to update item in PocketBase
    store[`update${stateKey.charAt(0).toUpperCase() + stateKey.slice(1).slice(0, -1)}`] = async (id, data) => {
      try {
        const record = await pb.collection(collection).update(id, data);
        store.$patch((state) => {
          const index = state[stateKey].findIndex(item => item.id === id);
          if (index !== -1) {
            state[stateKey][index] = record;
          }
        });
        return record;
      } catch (error) {
        console.error(`Failed to update ${stateKey.slice(0, -1)}:`, error);
      }
    };

    // Add a method to delete item from PocketBase
    store[`delete${stateKey.charAt(0).toUpperCase() + stateKey.slice(1).slice(0, -1)}`] = async (id) => {
      try {
        await pb.collection(collection).delete(id);
        store.$patch((state) => {
          state[stateKey] = state[stateKey].filter(item => item.id !== id);
        });
      } catch (error) {
        console.error(`Failed to delete ${stateKey.slice(0, -1)}:`, error);
      }
    };

    // Cleanup function
    return {
      unsubscribe: () => {
        unsubscribe();
      }
    };
  };
}