// Pinia Store
import { defineStore } from 'pinia'
// import vuexStore from '@/store' // for gradual conversion, see fullUserDetails

export const useSettingsStore = defineStore('settings', {
  // convert to a function
  state: () => ({
    theme: 'System',
    themes: [
      {
        name: 'System',
        variables: []
      },
      {
        name: 'Light',
        variables: [
          {
            name: 'bg-color',
            value: '#fefefe'
          },
          {
            name: 'text-color',
            value: '#0a0a0a'
          },
          {
            name: 'white-text',
            value: '#0a0a0a'
          },
          {
            name: 'secondary',
            value: '#f8f8f8'
          },
          {
            name: 'secondary-color',
            value: '#0a0a0a'
          },
          {
            name: 'secondary-callout',
            value: '#eaeaea'
          },
          {
            name: 'selected-color',
            value: '#cacaca'
          },
          {
            name: 'blue',
            value: '#1779ba'
          },
          {
            name: 'brightness',
            value: null
          },
          {
            name: 'border',
            value: '#e6e6e6'
          },
          {
            name: 'accordion-text',
            value: '#1779ba'
          },
          {
            name: 'secondary-callout-default',
            value: '#eaeaea'
          },
          // {
          //   name: 'green',
          //   value: '#e1faea'
          // },
          {
            name: 'green-border',
            value: '#13C25C'
          },
          {
            name: 'red',
            value: '#cc4b37'
          },
          {
            name: 'red2',
            value: '#bc0000'
          },
          {
            name: 'orange',
            value: '#d16100'
          },
          {
            name: 'green',
            value: '#61a773'
          },
          {
            name: 'magenta',
            value: '#cf4b9d'
          },
          {
            name: 'skyBlue',
            value: '#4b8aef'
          },
          {
            name: 'purple',
            value: '#9649c5'
          },
          {
            name: 'yellow',
            value: '#c3b200'
          }
        ]
      },
      {
        name: 'Dark',
        variables: [
          {
            name: 'bg-color',
            value: '#121212'
          },
          // {
          //   name: 'text-color',
          //   value: '#a5a5a5'
          // },
          {
                name: 'text-color',
                value: 'white'
              },
          {
            name: 'white-text',
            value: 'white'
          },
          {
            name: 'secondary',
            value: '#181818'
          },
          {
            name: 'secondary-color',
            value: '#a5a5a5'
          },
          {
            name: 'secondary-callout',
            value: '#888888'
          },
          {
            name: 'selected-color',
            value: '#1e1e1e'
          },
          {
            name: 'blue',
            value: '#0d486f'
          },
          {
            name: 'brightness',
            value: '.7'
          },
          {
            name: 'border',
            value: '#1e1e1e'
          },
          {
            name: 'accordion-text',
            value: '#fff'
          },
          {
            name: 'secondary-callout-default',
            value: '#242424'
          },
          // {
          //   name: 'green',
          //   value: '#0B7437'
          // },
          {
            name: 'green-border',
            value: '#13C25C'
          },
          {
            name: 'red',
            value: '#7a2d21'
          },
          {
            name: 'red2',
            value: '#700d0d'
          },
          {
            name: 'orange',
            value: '#703b0d'
          },
          {
            name: 'green',
            value: '#0d7026'
          },
          {
            name: 'magenta',
            value: '#700d4a'
          },
          {
            name: 'skyBlue',
            value: '#0d3370'
          },
          {
            name: 'purple',
            value: '#4a0d70'
          },
          {
            name: 'yellow',
            value: '#70670d'
          }
        ]
      }
    ],
    calendars: [],
    calendarUpdateFrequency: 1000 * 60,
    isOffline: false,
  }),
  getters: {
    hasEnabledCalendars: (state) => state.calendars.some(calendar => calendar.enabled)
  },
  actions: {
    initializeCalendars() {
      return this.fetchAllCalendars();
    },
    // no context as first argument, use `this` instead
    // async loadUser (id: number) {
    //   if (this.userId !== null) throw new Error('Already logged in')
    //   const res = await api.user.load(id)
    //   this.updateUser(res)
    // },
    // loadTheme() {
    //   window.setTheme(this.theme.toLowerCase())
      
    // },
    loadTheme: function () {
      console.log(`Theme: ${this.theme}`)

      if (this.theme === 'System') {
        if (
          window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
          // dark mode
          this.applyTheme('Dark')
        } else {
          this.applyTheme('Light')
        }
      } else {
        this.applyTheme(this.theme)
      }
    },
    setTheme(payload) {
      this.theme = payload
    },
    applyTheme(themeName) {
      const foundTheme = this.themes.find((each) => each.name === themeName)

      if (foundTheme) {
        foundTheme.variables.forEach((each) => {
          document.documentElement.style.setProperty(`--${each.name}`, each.value)
        })
      }
    }
    // easily reset state using `$reset`
    // clearUser () {
    //   this.$reset()
    // }
  },
  persist: {
    paths: ['theme', 'calendars', 'calendarUpdateFrequency']
  },
  pocketbase: {
    collection: 'Calendars',
    stateKey: 'calendars',
  }
})
