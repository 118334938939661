<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue"

// const getID = (theme) => `${settingsID}-${theme}`
const currentTime = ref('')
const fullDayName = ref('')
const timeOfDay = ref('')
const fullDate = ref('')

const updateDateTime = () => {
  const now = new Date();
  currentTime.value = now.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
  fullDayName.value = now.toLocaleDateString([], { weekday: 'long' });
  fullDate.value = now.toLocaleDateString(['en-US'], { year: 'numeric', month: 'long', day: 'numeric' });

  timeOfDay.value = now.toLocaleString([], { dayPeriod: 'short'}).replace('in the', '').replace('at ', '').trim()
  const localePeriod = timeOfDay.value.charAt(0).toUpperCase() + timeOfDay.value.slice(1);
  timeOfDay.value = localePeriod
}

const updateFrequency = 1000 * 60// Update every minute to keep time current
let interval
onMounted(() => {
  updateDateTime(); // Initial update
  interval = setInterval(updateDateTime, updateFrequency);
})

onUnmounted(() => {
  clearInterval(interval)
})

const time = computed(() => currentTime.value)
const dayName = computed(() => fullDayName.value)

</script>

<template>
  <div class="grid-x grid-padding-x text-center">
    <div class="cell"><h2 class="font-bold">{{ dayName }}</h2></div>
    <div class="cell"><h3>{{ timeOfDay }}</h3></div>
    <div class="cell"><h1 class="font-bold">{{ time }}</h1></div>
    <div class="cell"><h3>{{ fullDate }}</h3></div>
  </div>
</template>

<style scoped>
  h2 {
    font-size: 5rem;
    margin-bottom: 0;
  }
  h1 {
    font-size: 6rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 4rem;
    margin-bottom: 0;
  }
</style>