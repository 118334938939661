<script setup>
import { ref, onMounted, computed, inject, onUnmounted } from "vue"
import { useUsersStore } from "../stores/users";
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
// import { usePhrasesStore } from "../stores/phrases";

// Import the PocketBase JS library
// import client from '../pocketbase';

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    email: yup.string().email().required(),
  }),
});

const userStore = useUsersStore()

const loginWithPCO = async function() {
  
  

  userStore.loginWithPCO(pb)
}


const onSubmit = handleSubmit(async (values, {resetForm, setFieldError}) => {
    try {
      await userStore.resetPassword(values.email, pb)
      wasSuccess.value = true
      // resetForm()
      // hide the form and say success or something
      // if (userStore.isLoggedIn) {
      //   // forward to home
      //   router.push('/')
      // }
    } catch (error) {
      // show the error
      
      setFieldError('email', error.message)
    }
  
});

const resetPassword = () => {
  userStore.resetPassword(pb)
}

let pb

onMounted( async () => {
  pb = inject('client')

  // if (userStore.isLoggedIn) {
  //   // forward to home
  //   router.push('/')
  // }
})

const wasSuccess = ref(false)

const [email, emailAttrs] = defineField('email')
</script>



<template>
  <div class="grid-x grid-padding-x align-center">
    <div class="cell medium-3 small-8 text-center">
    <form v-if="!wasSuccess" @submit="onSubmit">
      <label>Email
      <input v-model="email" v-bind="emailAttrs" type="text" />
      </label>
      <span class="form-error" :class="{'is-visible': errors.email}">{{ errors.email }}</span>
      
      <input class="button expanded" type="submit" value="Request Reset">
    </form>
    <h4 v-else>A reset email was sent to {{ email }} if it is in the system.</h4>
    <RouterLink class="button hollow secondary expanded" to="/register">Register</RouterLink>
    <RouterLink class="button hollow secondary expanded" to="/login">Login</RouterLink>
    
    </div>
  </div>
</template>
