<script setup>
import { ref, onMounted, computed, inject, onUnmounted } from "vue"
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import DateAndTime from '../components/DateAndTime.vue'
import CalendarWeek from '../components/CalendarWeek.vue'
import CalendarMonth from '../components/CalendarMonth.vue'
import CalendarAgenda from '../components/CalendarAgenda.vue'

import useCalendarEvents from '../composables/useCalendarEvents.js'

import { useSettingsStore } from '../stores/settings'
const settingsStore = useSettingsStore()

import { useUsersStore } from "../stores/users";
const userStore = useUsersStore()

const router = useRouter()

const { calendars } = storeToRefs(settingsStore)

const { events } = useCalendarEvents(
  import.meta.env.VITE_CORS_PROXY_URL,
  calendars
)


onMounted(async () => {
  await settingsStore.initializeCalendars();
})
</script>

<template>
  <div class="">
    <div class="grid-x">
      <div class="cell large-6"><DateAndTime></DateAndTime><CalendarMonth :events="events"></CalendarMonth></div>
      <div class="cell large-6"><CalendarAgenda :events="events"></CalendarAgenda></div>
    </div>
    
    
  </div>
</template>
