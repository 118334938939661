<script setup>
import { ref, onMounted, computed, inject, onUnmounted } from "vue"
import { useUsersStore } from "../stores/users";
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
// import { usePhrasesStore } from "../stores/phrases";

// Import the PocketBase JS library
// import client from '../pocketbase';

import { useForm } from 'vee-validate';

import * as yup from 'yup';

const { errors, defineField, handleSubmit } = useForm({
  validationSchema: yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(6).max(24).required()
  }),
});

const userStore = useUsersStore()

const loginWithPCO = async function() {
  
  

  userStore.loginWithPCO(pb)
}


const onSubmit = handleSubmit(async (values, {resetForm, setFieldError}) => {
    try {
      await userStore.login(values.email, values.password, pb)
      resetForm()
      if (userStore.isLoggedIn) {
        // forward to home
        router.push('/')
      }
    } catch (error) {
      // show the error
      
      setFieldError('email', error.message)
    }
  
});

const resetPassword = () => {
  userStore.resetPassword(pb)
}

let pb

onMounted( async () => {
  pb = inject('client')

  if (userStore.isLoggedIn) {
    // forward to home
    router.push('/')
  }
})

const [email, emailAttrs] = defineField('email')
const [password, passwordAttrs] = defineField('password');
</script>



<template>
  <div class="grid-x grid-padding-x align-center">
    <div class="cell medium-3 small-8 text-center">
    <form @submit="onSubmit">
      <label>Email
      <input v-model="email" v-bind="emailAttrs" type="email" autocomplete="username" />
      </label>
      <span class="form-error" :class="{'is-visible': errors.email}">{{ errors.email }}</span>
      <label>Password
      <input v-model="password" v-bind="passwordAttrs" type="password" autocomplete="current-password" />
      </label>
      <span class="form-error" :class="{'is-visible': errors.password}">{{ errors.password }}</span>
      <input class="button expanded" type="submit" value="Login">
    </form>
    <RouterLink class="button clear secondary expanded" to="/password-reset">Reset Password</RouterLink>
    <RouterLink class="button hollow secondary expanded" to="/register">Register</RouterLink>
    
    </div>
  </div>
</template>
