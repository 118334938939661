<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";

import {
  getDayName,
  getDayOfMonth,
  getDaysDifference,
  getMinutesDifference,
  formatTime
} from "../utils/dateUtils";

import { groupUpcomingEvents, timeTilEvent } from "../utils/eventUtils";

const emit = defineEmits(["alert"]);

const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  day: {
    type: String,
    required: true,
  },
  agendaTimePosition: {
    type: String,
    default: "before",
  },
  countdownHourLimit: {
    type: Number,
    default: 3,
  },
  alertTime: {
    type: Number,
    default: 30,
  },
});

const timeTil = ref("");

const isMultiDayEvent = computed(() => {
  // check difference and make sure that it isn't marked an all day event

  const start = new Date(props.event.start);
  const end = new Date(props.event.end);
  const difference = getDaysDifference(start, end);
  if (difference > 0 && !props.event.allDay) {
    return true;
  }
  return false;
  return (
    start.getMonth() === end.getMonth() && start.getDate() !== end.getDate()
  );
});

const isAllDay = computed(() => {
  if (eventStartsThisDay.value || eventEndsThisDay.value) {
    return false;
  }
  const start = new Date(props.event.start);
  const end = new Date(props.event.end);

  // console.log('props.day', props.day)

  const groupDay = new Date(props.day + "T00:00:00"); //.getDate()
  //   const currentDay = (new Date()).getDate()
  // console.log('event.title', props.event.title)

  // console.log('startDay', startDay)
  // console.log('endDay', endDay)
  // console.log('groupDay', groupDay)
  // console.log('groupDay.getDate()', groupDay.getDate())

  return groupDay > start && groupDay < end;
  // return start.getMonth() === end.getMonth() && start.getDate() !== end.getDate()
});

const eventStartsThisDay = computed(() => {
  const start = new Date(props.event.start);
  const groupDay = new Date(props.day + "T00:00:00");
  return start.getDate() === groupDay.getDate();
});

const eventEndsThisDay = computed(() => {
  const end = new Date(props.event.end);
  const groupDay = new Date(props.day + "T00:00:00");
  return end.getDate() === groupDay.getDate();
});

const shouldShowTimeTil = computed(() => {
  const currentDay = new Date().getDate();
  const groupDay = new Date(props.day + "T00:00:00").getDate();

  const isToday = groupDay === currentDay
  const isAllDayEvent = props.event.allDay || isAllDay.value;

  if (isToday) {
    if (isAllDayEvent) {
      if (isMultiDayEvent.value && eventStartsThisDay.value) {
        return true;
      }
      // console.log("Here");
      // console.log('event.title', props.event.title)
      // console.log('groupDay', groupDay)
      // console.log('props.day', props.day)
      // console.log('isAllDay', isAllDay.value)

      return false;
    }
  } else {
    // console.log('event.title', props.event.title)
    //   console.log('groupDay', groupDay)
    //   console.log('props.day', props.day)
    //   console.log('isAllDay', isAllDay.value)
    if (isAllDay.value) {
      return false;
    }
  }
  return true;
});

// const alertTime = 30
const isWithinAlertTime = ref(false);
let interval;

onMounted(() => {
  // console.log('Component is mounted');

  let watchTime = props.event.start;

  if (isMultiDayEvent.value) {
    if (eventEndsThisDay.value) {
      watchTime = props.event.end;
    }
    timeTil.value = timeTilEvent(watchTime);

    interval = setInterval(() => {
      timeTil.value = timeTilEvent(watchTime);
    }, 1000);
  } else {
    timeTil.value = timeTilEvent(props.event.start);

    if (!props.event.allDay) {
      isWithinAlertTime.value =
        getMinutesDifference(new Date(), new Date(props.event.start)) <=
        props.alertTime;
      // console.log('getMinutesDifference(new Date(), new Date(props.event.start))', getMinutesDifference(new Date(), new Date(props.event.start)))
    }

    interval = setInterval(() => {
      timeTil.value = timeTilEvent(props.event.start);
      if (!props.event.allDay) {
        const oldTime = isWithinAlertTime.value === false;
        isWithinAlertTime.value =
          getMinutesDifference(new Date(), new Date(props.event.start)) <=
          props.alertTime;
        // console.log('timeTil.value', timeTil.value)

        // console.log('oldTime', oldTime)
        // console.log('newTime', isWithinAlertTime.value === true)

        if (oldTime && isWithinAlertTime.value === true) {
          // Notify
          console.log("ALERT!!!!");
          emit("alert");
        }
      }
    }, 1000);
  }
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<template>
  <div
    class="callout radius"
    :class="[{ alert: isWithinAlertTime }, event.class]"
  >
    <div class="grid-x">
      <div v-if="agendaTimePosition === 'before'" class="cell large-3">
        <template v-if="isAllDay">
          <h3 class="font-bold">All Day</h3>
        </template>
        <template v-else-if="isMultiDayEvent">
          <h3 class="font-bold">
            {{ getDayName(event.start) }} {{ formatTime(event.start) }}
          </h3>
          <h4>{{ getDayName(event.end) }} {{ formatTime(event.end) }}</h4>
        </template>
        <template v-else>
          <h3 class="font-bold">{{ formatTime(event.start) }}</h3>
          <h4>{{ formatTime(event.end) }}</h4>
        </template>
      </div>
      <div class="cell large-9">
        <h3>{{ event.title }}</h3>
        <h4 v-if="event.location" class="subheader">
          At: {{ event.location }}
        </h4>
        <h5 v-if="event.content" class="">Note: {{ event.content }}</h5>
        <h3 v-if="shouldShowTimeTil">
          <span class="font-bold">{{ timeTil }}</span>
        </h3>
      </div>
      <div
        v-if="agendaTimePosition === 'after'"
        class="cell large-3 text-right"
      >
        <template v-if="event.allDay || isAllDay">
          <h3 class="font-bold">All Day</h3>
        </template>
        <template v-else-if="isMultiDayEvent">
          <h3 class="font-bold">
            {{ eventStartsThisDay ? "Starts" : "Ends" }}
          </h3>
          <h4 v-if="eventStartsThisDay">{{ formatTime(event.start) }}</h4>
          <h4 v-if="eventEndsThisDay">{{ formatTime(event.end) }}</h4>
        </template>
        <template v-else>
          <h3 class="font-bold">{{ formatTime(event.start) }}</h3>
          <h4>{{ formatTime(event.end) }}</h4>
        </template>
      </div>
    </div>
  </div>
</template>


<style>
.agenda {
  margin-top: 2rem;
}

.event-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.event-time {
  margin-right: 1rem;
  font-weight: bold;
}
</style>